.main {
  width: 100%;
}

.main .secTitle .title {
  position: relative;
  width: max-content;
  color: var(--textColor);
  margin: 1rem 0;
  z-index: 2;
}

.main .secTitle .title::after {
  position: absolute;
  content: '';
  background: var(--secondaryColor);
  height: 5px;
  width: 120px;
  right: 0;
  bottom: 5px;
  z-index: -1;
}

.main .setContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.main .singleDestination {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background: var(--cardBG);
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: rgb(255, 255, 255);
    box-shadow: 1px 4px 4px rgba(84, 84, 114, 0.549);
  }
}

.main .singleDestination .imageDiv {
  height: 180px;
  width: 100%;
  overflow: hidden;
}

.main .singleDestination .imageDiv img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.main .singleDestination:hover .imageDiv img {
  transform: scale(1.1);
}

.main .singleDestination .destinationDetails {
  padding: 1rem;
}

.main .singleDestination .destinationDetails .destTitle {
  color: var(--blackColor);
  font-size: 1.25rem;
}

.main .singleDestination .destinationDetails .continent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--textColor);
}

.main .singleDestination .destinationDetails .continent .icon {
  font-size: 18px;
}

.main .singleDestination .destinationDetails .fees {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 1rem 0;
  border-top: 1.5px solid var(--greyText);
  border-bottom: 1.5px solid var(--greyText);
}

.main .singleDestination .destinationDetails .fees .grade {
  color: var(--textColor);
  max-width: 100px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.main .singleDestination .destinationDetails .fees .grade small {
  text-align: center;
  background: var(--gradientColor);
  border-radius: 1rem;
  color: var(--whiteColor);
  padding: 1px 10px;
  font-size: 10px;
}

.main .singleDestination .destinationDetails .fees .price {
  font-size: 2rem;
  color: var(--blackColor);
}

.main .singleDestination .destinationDetails .desc {
  font-size: 13.5px;
  color: var(--textColor);
  font-weight: 500;
}

.main .singleDestination .destinationDetails .btn {
  margin-top: 1rem;
  color: var(--whiteColor);
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.main .singleDestination .destinationDetails .btn .icon {
  font-size: 18px;
}
