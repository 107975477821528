:root {
  --primaryColor: hsl(199, 100%, 33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--whiteColorDeam);
  width: 100%;
  padding: 1rem;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(2, 15, 19, 0.204);
}
.header .logo {
  color: var(--blackColor);
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.header .logo svg {
  margin-right: 0.5rem;
}
.header .navBar {
  position: absolute;
  background: var(--whiteColorDeam);
  height: -moz-max-content;
  height: max-content;
  width: 80%;
  border-radius: 1rem;
  top: -200rem; /* This should be adjusted based on the active state */
  left: 50%;
  padding: 1rem;
  transform: translateX(-50%);
  z-index: 2000;
  box-shadow: 0 2px 4px rgba(2, 15, 19, 0.904);
  transition: 0.2s ease-in-out;
}
.header .navBar .navLists {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
}
.header .navBar .navLists .navItem {
  padding: 0.5rem 0;
}
.header .navBar .navLists .navItem .navLink {
  color: var(--textColor);
  font-size: 0.9rem;
  font-weight: 600;
}
.header .navBar .navLists .navItem .navLink:hover {
  color: var(--primaryColor);
}
.header .navBar .navLists .btn {
  margin-top: 1rem;
}
.header .navBar .navLists .btn a {
  font-weight: 600;
  color: var(--whiteColor);
}
.header .navBar .closeNavbar {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  color: var(--primaryColor);
}
.header .navBar .closeNavbar:hover {
  color: var(--secondaryColor);
}
.header .toggleNavbar {
  cursor: pointer;
}
.header .toggleNavbar .icon {
  font-size: 25px;
  color: var(--primaryColor);
}
.header .toggleNavbar:hover .icon {
  color: var(--secondaryColor);
}
.header .activeNavbar {
  top: 7rem; /* Adjust the position as needed */
}/*# sourceMappingURL=navbar.css.map */