:root {
  --primaryColor: hsl(199, 100%, 33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
}

.home {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  .overlay {
    position: relative;
    height: 100%;
    width: 100%;
    background: rgb(47, 106, 127, 0.8);
    top: 0;
    bottom: 0;
    z-index: 1;
    // mix-blend-mode: hard-light;
  }
  video {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    object-fit: cover;
  }
  .homeContent {
    position: relative;
    width: 100%;
    height: max-content;
    padding: 8rem 1.5rem 3rem;
    display: flex;
    row-gap: 3rem;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin: auto;
    color: var(--whiteColor);
    z-index: 2;
    .textDiv {
      padding: 2rem 0;
      .smallText {
        font-size: 14px;
        text-transform: uppercase;
        position: relative;
      }

      .homeTitle {
        position: relative;
        font-style: 11px;
      }
    }

    .cardDiv {
      position: relative;
      padding: 2rem 1rem;
      background: var(--whiteColor);
      border-radius: 10px;
      gap: 1rem;
      label {
        display: block;
        color: var(--textColor);
        padding-bottom: 10px;
        font-size: 13px;
        font-weight: 500;
      }
      .label_total {
        justify-content: space-between;
        align-items: center;
        .total {
          color: var(--textColor);
          font-size: 18px;
          padding-bottom: 10px;
        }
      }
      .input {
        height: 40px;
        padding: 0 1rem;
        background: var(--inputColor);
        border-radius: 3rem;
        .icon{
            font-size: 30px;
            color: var(--primaryColor);
            margin-left: 10px;
        }
        .input[type=range]{
            background: blue;


        }

        input {
          background: none;
          border: none;
          outline: none;
          width: 100%;
          font-size: 13px;
          font-weight: 500;

          &::placeholder {
            font-size: 13px;
          }
        }
      }
      .searchOptions{
        position: absolute;
        background: var(--gradientColor);
        width: max-content;
        bottom: -0.7rem;
        column-gap: .8rem;
        padding: 5px 1rem;
        border-radius: 3rem;
        left: 50%;
        transform: translate(-50%);
        justify-content: center;
        cursor: pointer;
        &:hover{
            background: var(--primaryColor);
        }
        .icon{
            font-size: 18px;
        }
        span{
            font-size: 13px;
        }


      }

    }
    .homeFooterIcons{
        width: 100%;
        justify-content: space-between;
        .icon{
            font-size: 20px;
            margin: 0 5px;
            color: var(--whiteColor);
            font-weight: 600;
            &:hover{
                color: var(--primaryColor);
                

            }
        }
    }

  }
}
